import React from "react";
import "../pages/mystyles.scss";
import Header from "../../../../components/header/Header";
import Sec7 from "../../../../components/home/components/sec7/sec7";
// import TrendingArticles from "../../../../components/blocks/trendingArticles/TrendingArticles";
import Footer from "../../../../components/footer/Footer";
import List2 from "../../../../components/home/components/list/List2";
import Sidebar from "../../../../components/main/sidebar/Sidebar";
import Bigsection from "../../../../components/home/components/bigsection/Bigsection";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

const IndexPage = (props) => {
  const { data } = props;
  const { pageContext } = props;
  const { postSearchData } = pageContext;
  const { allPosts, options } = postSearchData;

  let hiroStory = data?.cms?.recent_story?.filter(checkFetured);
  let hiroList = data?.cms?.recent_listicle?.filter(checkFetured);
  function checkFetured(element) {
    return element?.is_feature_post;
  }
  return (
    <div className="layout">
      <Header category={data?.cms?.categories} domain={data?.cms?.domain} allPosts={allPosts} engine={options} pages={data?.cms?.pages} />
      <Helmet>
        <link rel="canonical" href={`https://${data?.cms?.domain?.domain_url}/`} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Trustworthy Lifestyle and Health-related Information | Ofmum" />
        <meta name="verification" content="873c9b4db3b1cf1e5827be688fdcbcaf" />
        <meta property="og:description" content="Trustworthy Lifestyle and Health-related Information | Ofmum" />
        <meta property="og:url" content={`https://${data?.cms?.domain?.domain_url}/`} />
        <meta property="og:site_name" content={data?.cms?.domain?.name} />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-WM27LRZQNE"></script>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://www.schema.org",
            "@type": "WebSite",
            name: "Ofmum",
            url: "https://ofmum.com",
          })}
        </script>
        <link rel="canonical" href={`https://${data?.cms?.domain?.domain_url}`} />
        <title>Trustworthy Lifestyle and Health-related Information | Ofmum</title>
        <meta name="title" content={"Trustworthy Lifestyle and Health-related Information | Ofmum"} />
        <meta
          name="description"
          content={"Ofmum is a trustworthy online platform to check out experts' views on different brands and hot topics including lifestyle, health, relationship, and more. Visit us."}
        />
        <script>
          {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-WM27LRZQNE');
          `}
        </script>
        <meta name="webgains-site-verification" content="9za3nmsz" />
        <meta name="partnerboostverifycode" content="32dc01246faccb7f5b3cad5016dd5033" />
        <meta name="lhverifycode" content="32dc01246faccb7f5b3cad5016dd5033" />
      </Helmet>
      {/* <TrendingArticles /> */}
      <Sec7 data={data} />
      <Bigsection data={hiroList} />
      <div className="container is-max-widescreen mt-2">
        <div className="columns m-0">
          <div className="column is-two-thirds">
            <List2 data={data?.cms?.recent_story} />
          </div>
          <div className="column">
            <Sidebar recent_story={hiroStory} />
          </div>
        </div>
      </div>
      <Footer pages={data?.cms?.pages} domain={data?.cms?.domain} email={"editor@ofmum.com"} />
    </div>
  );
};
export default IndexPage;

export const query = graphql`
  query home {
    cms {
      domain: CMS_Domain(id: "8") {
        id
        logo
        logo_light
        domain_url
        name
        description
        favicon
        header_script
      }
      categories: CMS_CategoryByDomain(domain_id: "8") {
        name
        slug
      }
      tech_listicle: CMS_DomainWiseLists(domain_id: 8, category_id: "24") {
        title
        image
        slug
        categories {
          name
        }
      }
      tech_story: CMS_DomainWiseStories(domain_id: "8", category_id: "24") {
        image
        slug
        title
        categories {
          name
        }
      }
      recent_listicle: CMS_DomainWiseLists(domain_id: "8") {
        title
        is_feature_post
        image
        slug
        description
        categories {
          name
        }
      }
      recent_story: CMS_DomainWiseStories(domain_id: "8") {
        image
        slug
        title
        content
        is_feature_post
        categories {
          name
        }
      }
      pages: Published_Pages(domain_id: "1") {
        slug
        title
      }
    }
  }
`;
